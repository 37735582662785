import { createRouter, createWebHistory } from 'vue-router';
import MessageApp from '../views/MessageApp.vue';
import AdminApp from '../views/AdminApp.vue';

const routes = [
  {
    path: '/',
    name: 'AdminApp',
    component: AdminApp,
    bgColor: "#fff",
    color: "#000",
    textColor: "red",
  },
  {
    path: '/messageapp',
    name: 'MessageApp',
    component: MessageApp,
    bgColor: "#000",
    color: "#fff",
    textColor: "blue",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;