<template>
    <MessageForm ref="messageForm" />
</template>

<script>
  export default {
  }
</script>

<style>
  .car-selector label {
    margin-right: 5px;
  }
  .message-templates {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  .template {
    float: left;
    width: 100px;
    height: 100px;
    margin-right: 40px;
  }
  .template.custom-message {
    position: relative;
    width: 200px;
    height: 140px;
  }
  .template.message-button {
    border-radius: 5px;
    box-shadow: 2px 2px 2px #a1a1a1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
  }
  .template.custom-message textarea {
    width: 190px;
    height: 70px;
    margin-bottom: 5px;
  }
  .template.custom-message button {
    float: right;
  }
  .template.message-boxboxbox {
    background-color: red;
    text-align: center;
    color: white;
  }
  .template.message-yellowflag {
    background-color: yellow;
    text-align: center;
  }
  .template.message-greenflag {
    background-color: green;
    color: white;
    text-align: center;
    margin-right: 0;
  }

  .message-list {
    margin: auto;
    width: 470px;
    padding: 10px;
  }
  .message-list ul {
    width: 470px;
    list-style-type: none;
    padding: 0;
  }
  .message-list ul li {
    text-align: left;
    margin-bottom: 10px;
    border-top: 1px solid #a1a1a1;
    padding: 10px;
  }
  .message-list ul li.odd {
    background-color: #a1a1a1;
  }
  .message-list ul li .car {
    display: block;
    font-style: italic;
  }
</style>