import { createApp } from 'vue'
import App from './App.vue'
import MessageDisplay from './components/MessageDisplay.vue'
import MessageForm from './components/MessageForm.vue'
import router from './router'
// import axios from 'axios'
// import VueAxios from 'vue-axios'

const app = createApp(App);
// adding the message display component
app.component("MessageDisplay", MessageDisplay);
app.component("MessageForm", MessageForm);
// app.use(VueAxios, axios)
app.use(router);
app.mount('#app');