<template>
  <div>
    <div class="connection-status">
      <div class="info-icon" :class="{connected: isConnected}"></div>
      <div class="info-message">{{ infoMessage }}</div>
    </div>
    <!-- <div v-for="message in messages" :key="message.ID" class="message">{{ message.Text }}</div> -->
    <div class="message" @click="clearMessage">{{ message }}</div>
  </div>
</template>

<script>
export default {
  emits: ['messageRead'],
  data() {
    return {
      // messages: []
      message: '',
      messageId: 0,
      infoMessage: '',
      isConnected: false
    }
  },
  methods: {
    addMessage(message) {
      this.message = message.Text;
      this.messageId = message.ID;
    },
    clearMessage() {
      this.$emit('messageRead', this.messageId);

      this.message = null;
      this.messageId = 0;
    },
    setIsConnected() {
      this.isConnected = true;
      this.setInfoMessage('Connected');
    },
    setIsDisconnected() {
      this.isConnected = false;
      this.setInfoMessage('Disconnected...');
    },
    setInfoMessage(message) {
      this.infoMessage = message;
    }
  }
}
</script>