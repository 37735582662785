<template>
  <div class="message-app">
    <MessageDisplay @messageRead="handleMessageRead" ref="messageDisplay" />
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      authString: null,
      teamId: null,
      carId: null,
      socket: null,
      currentMessage: null,
    }
  },
  methods: {
    close() {
      if (this.socket && this.socket.readyState) {
        this.socket.close();
        this.socket = null;
      }
    },
    connect() {
      // initializing the socket, adding the current car and team to the connection string as well
      this.socket = new WebSocket(process.env.VUE_APP_SERVER_WS_URI + "?car=" + this.carId + "&team=" + this.teamId);

      this.socket.onopen = (event) => {
        console.log("open", event);
        this.$refs.messageDisplay.setIsConnected();
      };
      this.socket.onmessage = (event) => {
        console.log("message", event.data);
        // parsing and saving the message
        this.currentMessage = JSON.parse(event.data);
        // sending the message to the display component
        this.$refs.messageDisplay.addMessage(this.currentMessage);
      };
      this.socket.onclose = (event) => {
        console.log("close", event);
        if (this.$refs.messageDisplay != null) {
          this.$refs.messageDisplay.setIsDisconnected();
        }

        // try to reconnect
        setTimeout(() => this.reconnect(), 1000);
      };
      this.socket.onerror = (event) => {
        console.log("error", event);
        // shows the error message on the user's display
        this.$refs.messageDisplay.setInfoMessage(event.data);
      };
    },
    reconnect() {
      this.socket = null;
      this.connect();
    },
    handleMessageRead(id) {
      // sends a message back through the socket, saying the message has been read
      this.socket.send({
        type: "messageRead",
        id: id,
        carId: this.carId,
      })
    },
    // setups the car and team, to receive messages from, using the given auth query string
    async setupCarAndTeam() {
      await axios.get(process.env.VUE_APP_SERVER_URI + '/authenticate/' + this.authString, {
          headers: {
              'Content-type': 'application/json',
          }
      }).then(response => {
          // setting the team name from the response
          this.teamId = response.data.teamId;
          this.carId = response.data.id;

          // connect to the websocket service
          this.connect();
      })
      .catch(error => {
          console.error('Error sending message:', error);
          this.$refs.messageDisplay.setInfoMessage("Authentication token is faulty");
      });
    }
  },
  mounted() {
    // setting the background color and text color on the body
    Object.assign(document.body.style, { backgroundColor: "#000", color: "#fff" });

    // storing the authentication token
    this.authString = this.$route.query.auth;
    // trying to validate the token
    this.setupCarAndTeam();
  },
  created() {
    // this.connect();
  },
  beforeUnmount() {
    this.close();

    Object.assign(document.body.style, { backgroundColor: '' });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}

.message-app .message {
  font-size: 15vw;
  max-width: 100%;
}
.message-app .connection-status {
  position: absolute;
  top: 20px;
  left: 20px;
}
.message-app .connection-status .info-message {
  float: left;
  margin-left: 5px;
  line-height: 22px;
  font-size: 20px;
}
.message-app .connection-status .info-icon {
  position: relative;
  float: left;
  width: 20px;
  height: 20px;
  background-color: red;
}
.message-app .connection-status .info-icon.connected {
  background-color: green;
}
</style>
