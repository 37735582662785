<template>
    <div>
      <h1>Send beskeder til dit hold: {{ teamName }}</h1>
    </div>

    <div class="car-selector">
        <label for="carId">Vælg bil:</label>
        <select v-model="carId" id="carId" @change="carSelected()">
            <option value="">Alle biler</option>
            <option v-for="(car, index) in cars" :key="index" :value="car.id">{{ car.name }}</option>
        </select>
        <div class="car-info">
            <a :href="carUrl" target="_blank">{{ carUrlMessage }}</a>
        </div>
    </div>
    <div class="message-templates">
        <div class="template custom-message">
            <form @submit.prevent="submitMessage">
                <div>Skriv egen besked her</div>
                <textarea v-model="message" placeholder="..."></textarea>
                <button type="submit">Send</button>
            </form>
        </div>
        <div class="template message-button message-boxboxbox" @click="sendMessage(this.carId, this.teamId, 'BOX BOX BOX')">
            <p>BOX BOX BOX</p>
        </div>
        <div class="template message-button message-yellowflag" @click="sendMessage(this.carId, this.teamId, 'Safetycar')">
            <p>Safetycar</p>
        </div>
        <div class="template message-button message-greenflag" @click="sendMessage(this.carId, this.teamId, 'Grønt flag')">
            <p>Grønt flag</p>
        </div>
    </div>
    <div class="message-list">
        <h2>Sendte beskeder</h2>
        <ul>
            <li v-for="(message, index) in messages.slice().reverse()" :key="index" :value="message.id">
                <!-- <span class="car">{{ message.carId }}</span> -->
                <span class="car">{{ message.car ?? 'Alle' }}</span>
                <span class="message">{{ message.text }}</span>
            </li>
        </ul>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    data() {
      return {
        carId: '',
        carUrl: '',
        carUrlMessage: '',
        teamId: null,
        cars: [],
        message: '',
        messages: [],
        teamName: ''
      };
    },
    mounted() {
        this.teamId = this.$route.query.team
        this.getCars();
    },
    methods: {
        async getCars() {
            await axios.get(process.env.VUE_APP_SERVER_URI + '/team/' + this.teamId, {
                headers: {
                    'Content-type': 'application/json',
                }
            }).then(response => {
                // setting the team name from the response
                this.teamName = response.data.name;

                let cars = []
                response.data.cars.forEach(function(car) {
                  var url = '/messageapp/?car=' + car.id + '&team=' + car.teamId + '&auth=' + car.authToken;
                  console.log(car, url);
                  cars.push({id: car.id, name: car.name, url: url})
                })
                this.cars = cars
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
        },
        // handles messageform submits
        submitMessage() {
            this.sendMessage(this.carId, this.teamId, this.message);
        },
        carSelected() {
          if (this.carId == '') {
            this.carUrlMessage = '';
            this.carUrl = '';
            return;
          }

          this.carUrlMessage = 'Link til bil i beskedapp';
          this.carUrl = this.cars.find(car => car.id == this.carId).url;
        },
        // sends the given message
        sendMessage(carId, teamId, message) {
            const headers = {
                'Content-type': 'application/json',
            };
            axios.post(process.env.VUE_APP_SERVER_URI + '/message',
                JSON.stringify({
                    carId: carId == '' ? null : carId,
                    teamId: teamId ?? null,
                    text: message
                }),
                { headers }
            )
            .then(response => {
                console.log('Message sent successfully:', response.data);
                let messageData = response.data;
                messageData.car = 'Alle biler';

                this.cars.forEach(function(carData) {
                    if (carData.id == messageData.carId) {
                        messageData.car = carData.name;
                    }
                });
                this.messages.push(messageData);
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
        }
    }
  }
  </script>